import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../../component/table";
import Button from "antd/es/button";
import License from "../../../../type/License";
import ActiveStatus from "../../../../enum/ActiveStatus";
import { addDays } from "date-fns";
import ValidCellRenderer from "../../../../component/table/component/cellRenderers/ValidCellRenderer";
import ActiveStatusCellRenderer from "../../../../component/table/component/cellRenderers/ActiveStatusCellRenderer";

interface Props {}

const rowData: License[] = [
  {
    id: 1,
    name: "Autodocu",
    internalName: "IncArchive",
    createdAt: new Date(),
    modifiedAt: addDays(new Date(), 3),
    host: "DNBX004-004",
    description: "",
    valid: true,
    status: ActiveStatus.ACTIVE,
  },
  {
    id: 2,
    name: "Disposition",
    internalName: "IncDisposition",
    createdAt: new Date(),
    modifiedAt: new Date(),
    host: "DNBX004-004",
    description: "",
    valid: true,
    status: ActiveStatus.INACTIVE,
  },
  {
    id: 3,
    name: "FAX",
    internalName: "IncDocumentManage...",
    createdAt: addDays(new Date(), 3),
    modifiedAt: new Date(),
    host: "DNBX004-004",
    description: "",
    valid: false,
    status: ActiveStatus.ACTIVE,
  },
  {
    id: 4,
    name: "IDHF83",
    internalName: "IncFax",
    createdAt: addDays(new Date(), -3),
    modifiedAt: new Date(),
    host: "DNBX004-002",
    description: "",
    valid: true,
    status: ActiveStatus.ACTIVE,
  },
  {
    id: 5,
    name: "DEBSF32L",
    internalName: "IncHISConnect",
    createdAt: new Date(),
    modifiedAt: addDays(new Date(), -3),
    host: "DNBX004-001",
    description: "",
    valid: true,
    status: ActiveStatus.ACTIVE,
  },
];

const WorkstationLicense: React.FC<Props> = () => {
  const { t } = useTranslation();

  // Column Definitions: Defines & controls grid columns.
  const [colDefs] = useState([
    { field: "name" },
    { field: "internalName" },
    { field: "createdAt" },
    { field: "modifiedAt" },
    { field: "host" },
    { field: "description" },
    { field: "valid", cellRenderer: ValidCellRenderer },
    { field: "status", cellRenderer: ActiveStatusCellRenderer },
  ]);

  return (
    <Table
      actions={[
        <Button type="primary">
          {t("scene.systemSettings.workstationLicense.addNew")}
        </Button>,
      ]}
      title={t("scene.systemSettings.workstationLicense.title")}
      count={1000}
      rowData={rowData}
      colDefs={colDefs}
    />
  );
};

export default WorkstationLicense;
