import React from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import styles from "./ValidCellRenderer.module.scss";

// TODO: use proper interface
const ValidCellRenderer = (e: any, d: any) => {
  return e.value ? (
    <CheckCircleOutlined className={styles.valid} />
  ) : (
    <CloseCircleOutlined className={styles.invalid} />
  );
};

export default ValidCellRenderer;
