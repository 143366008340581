const i18n = {
  en: {
    translation: {
      "scene.notFound.info": "Not found",
    },
  },
  ge: {
    translation: {
      "scene.notFound.info": "Not found",
    },
  },
};

export default i18n;
