import i18n from "i18next";
import deepmerge from "deepmerge";
import { initReactI18next } from "react-i18next";
import sceneI18n from "../scene/scene.i18n";
import { Resource } from "i18next/typescript/options";

const resources = deepmerge.all([sceneI18n]) as Resource;

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
