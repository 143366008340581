import React from "react";
import styles from "./App.module.scss";
import { Route } from "react-router-dom";
import NotFound from "./notFound";
import SystemSettings from "./systemSettings";
import { Routes } from "react-router";
import AppRoute from "./AppRoute";
import SystemSettingsRoute from "./systemSettings/SystemSettingsRoute";
import WorkstationLicense from "./systemSettings/pages/WorkstationLicense";

function App() {
  return (
    <div className={styles.container}>
      <Routes>
        <Route path={AppRoute.SYSTEM_SETTINGS} element={<SystemSettings />}>
          <Route
            path={SystemSettingsRoute.WORKSTATION_LICENSE}
            element={<WorkstationLicense />}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
