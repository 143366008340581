import React from "react";
import styles from "./PageContainer.module.scss";
import Typography from "antd/es/typography";
import Row from "antd/es/row";
import Col from "antd/es/col";

const { Title } = Typography;

interface Props {
  actions?: React.ReactNode[];
  children: React.ReactNode;
  title: React.ReactNode;
}

const PageContainer: React.FC<Props> = ({ actions, children, title }) => {
  return (
    <div className={styles.container}>
      <Row align="middle" justify="space-between">
        <Col>
          <Title level={3}>{title}</Title>
        </Col>
        <Col>
          <Row gutter={8}>{actions?.map((action) => <Col>{action}</Col>)}</Row>
        </Col>
      </Row>
      <div className={styles.pageWrapper}>{children}</div>
    </div>
  );
};

export default PageContainer;
