import React from "react";
import { useTranslation } from "react-i18next";
import PageContainer from "../../component/layout/PageContainer";
import { Outlet } from "react-router-dom";
import Tabs from "antd/es/tabs";
import styles from "./SystemSettings.module.scss";

interface Props {}

const SystemSettings: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <PageContainer title={t("scene.systemSettings.title")}>
      <Tabs
        className={styles.container}
        defaultActiveKey="1"
        size="small"
        type="card"
        items={[
          {
            label: "Workstation License",
            key: "wl",
            children: <Outlet />,
          },
        ]}
      />
    </PageContainer>
  );
};

export default SystemSettings;
