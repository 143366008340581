const i18n = {
  en: {
    translation: {
      "scene.systemSettings.workstationLicense.title": "Licence",
      "scene.systemSettings.workstationLicense.addNew": "Add New",
    },
  },
  ge: {
    translation: {
      "scene.systemSettings.workstationLicense.title": "Licence",
    },
  },
};

export default i18n;
