import React from "react";
import styles from "./Table.module.scss";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Identify from "../../type/common/Identify";
import cn from "classnames";

const { Title } = Typography;

interface Props<T extends Identify> {
  actions?: React.ReactNode[];
  // TODO: get interface
  colDefs: any;
  count: number;
  rowData: T[];
  title: React.ReactNode;
}

const Table = <T extends Identify>({
  actions,
  colDefs,
  count,
  rowData,
  title,
}: Props<T>) => {
  // const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Row align="middle" justify="space-between">
        <Col>
          <Title className={styles.title} level={4}>
            {title}
          </Title>
        </Col>
        <Col>
          <Row gutter={8}>{actions?.map((action) => <Col>{action}</Col>)}</Row>
        </Col>
      </Row>
      <div className={cn(styles.tableWrapper, "ag-theme-quartz-dark")}>
        <AgGridReact rowData={rowData} columnDefs={colDefs} />
      </div>
    </div>
  );
};

export default Table;
