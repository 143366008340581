import React from "react";
import Select from "antd/es/select";
import styles from "./ActiveStatusCellRenderer.module.scss";
import ActiveStatus from "../../../../../enum/ActiveStatus";

// TODO: use proper interface
const ActiveStatusCellRenderer = (e: any, d: any) => {
  return (
    <Select
      className={styles.select}
      value={e.value}
      options={[
        { value: ActiveStatus.ACTIVE, label: "Active" },
        { value: ActiveStatus.INACTIVE, label: "Inactive" },
      ]}
    />
  );
};

export default ActiveStatusCellRenderer;
