import deepmerge from "deepmerge";
import workstationLicenseI18n from "./pages/WorkstationLicense/WorkstationLicense.i18n";
import { Resource } from "i18next/typescript/options";

const i18n = {
  en: {
    translation: {
      "scene.systemSettings.title": "Internal Configuration",
    },
  },
  ge: {
    translation: {
      "scene.systemSettings.title": "Willkommen bei Medavis",
    },
  },
};

const resources = deepmerge.all([i18n, workstationLicenseI18n]) as Resource;

export default resources;
